"use client";

import React from "react";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "@gonoodle/gn-universe-ui";
import { usePathname } from "next/navigation";

import { ROUTE_PREFIX } from "../../constants";
import { useIntendedPath, useRouter } from "../../hooks";

export default function RedirectToLoginButton({
  children = "Log In",
  referrer = {},
  onPress = () => {},
  ...delegated
}) {
  const pathname = usePathname();
  const router = useRouter();
  const { intendedPath } = useIntendedPath();

  const currentIntendedPath = intendedPath || pathname;

  return (
    <Button
      variant={BUTTON_VARIANTS.lightOutline}
      size={BUTTON_SIZES.sm}
      onPress={(e) => {
        router.push(
          `/${ROUTE_PREFIX.LOGIN}?intended_path=${currentIntendedPath}`,
          undefined,
          referrer,
        );

        onPress(e);
      }}
      {...delegated}
    >
      {children}
    </Button>
  );
}
