"use client";

import React from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import {
  isLocalURL,
  isSubdomain,
  concatenateQueryParams,
  invariant,
} from "@gonoodle/gn-universe-utils";

import { ROUTE_PREFIX } from "../../constants";
import { useLogEvents } from "../../contexts/Analytics";
import { useReferrer } from "../../contexts/Referrer";
import { useRouter } from "../../hooks";

function validateRoute(href) {
  const pathname = href.split("?")[0];
  const urlParts = pathname.split("/");
  const routeKey = urlParts[1] || ROUTE_PREFIX.HOME;

  const validRoutes = new Set([
    ROUTE_PREFIX.HOME,
    ROUTE_PREFIX.PROFILES,
    ROUTE_PREFIX.TROPHIES,
    ROUTE_PREFIX.ACCOUNT_SETTINGS,
    ROUTE_PREFIX.LOGIN,
    ROUTE_PREFIX.REGISTRATION,
    ROUTE_PREFIX.FORGOT_PASSWORD,
    ROUTE_PREFIX.FAVORITES,
    ROUTE_PREFIX.COMPANY,
    ROUTE_PREFIX.SEARCH,
    ROUTE_PREFIX.CURRICULUM,
    ROUTE_PREFIX.VIDEOS,
    ROUTE_PREFIX.ACTIVITIES,
    ROUTE_PREFIX.GAMES,
    ROUTE_PREFIX.TAGS,
    ROUTE_PREFIX.NEWSLETTER_SIGNUP,
    ROUTE_PREFIX.EXTERNAL_LINK_DISCLAIMER,
  ]);

  invariant(
    validRoutes.has(routeKey),
    `No registered path found for href: ${href}`,
  );
}

function resolveExternalHref(href) {
  const path = typeof href === "string" ? href : href.pathname;
  const query = typeof href === "string" ? {} : href.query;

  return concatenateQueryParams(`/${ROUTE_PREFIX.EXTERNAL_LINK_DISCLAIMER}`, {
    href: path,
    ...query,
  });
}

const Link = React.forwardRef(function Link(
  {
    href,
    children,
    events = [],
    legacyBehavior = false,
    referrer = {},
    as = href,
    onPress = () => {},
    ...rest
  },
  forwardedRef,
) {
  const router = useRouter();
  const { logEvents } = useLogEvents({
    events,
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });
  const { setReferrer } = useReferrer();
  const path = typeof href === "string" ? href : href.pathname;

  invariant(
    !(
      legacyBehavior &&
      children &&
      React.Children.toArray(children).length > 1
    ),
    `Link with legacyBehavior=true can only have one child got ${
      React.Children.toArray(children).length
    }`,
  );

  React.useEffect(() => {
    if (isLocalURL(path)) {
      validateRoute(path);
    }
  }, [path]);

  const handleLinkPress = (e) => {
    onPress(e);
    if (e.defaultPrevented) return;

    setReferrer(referrer);

    logEvents();

    if (
      !isLocalURL(path) &&
      !isSubdomain(process.env.NEXT_PUBLIC_WEB_URL, path)
    ) {
      e.preventDefault();
      router.push(resolveExternalHref(href));
    }
  };

  return (
    <NextLink
      href={href}
      as={as}
      legacyBehavior={legacyBehavior}
      ref={forwardedRef}
      onClick={legacyBehavior ? undefined : handleLinkPress}
      {...rest}
    >
      {legacyBehavior
        ? React.cloneElement(children, { onClick: handleLinkPress })
        : children}
    </NextLink>
  );
});

Link.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object,
    }),
  ]).isRequired,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object,
    }),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired,
      properties: PropTypes.shape({}).isRequired,
    }),
  ),
  legacyBehavior: PropTypes.bool,
  referrer: PropTypes.shape({
    sourcePage: PropTypes.string,
    sourcePageType: PropTypes.string,
    sourceElement: PropTypes.string,
    sourceName: PropTypes.string,
  }),
  onPress: PropTypes.func,
};

export default Link;
