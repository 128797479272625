"use client";

import React from "react";
import {
  Pressable,
  AgeGatedPressable,
  Image,
  NavigationMenu,
  Tooltip,
} from "@gonoodle/gn-universe-ui";
import { Popover } from "@gonoodle/gn-universe-ui/lib/next";
import {
  HeartIcon,
  SearchIcon,
  MenuIcon,
  XIcon,
  SwitchHorizontalIcon,
  UserIcon,
  QuestionMarkCircleIcon,
  LogoutIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import {
  SECTIONS,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import useMeasure from "react-use-measure";
import { twMerge } from "tailwind-merge";
import NextImage from "next/image";
import { camelize } from "humps";

import { useUser } from "../../contexts/user";
import {
  useProfile,
  useCurriculum,
  useHasCurriculumAccess,
  useRouter,
} from "../../hooks";
import {
  ROUTE_PREFIX,
  PROFILE_TYPES,
  ROUTE_PATHS,
  URLS,
  ELEMENT_IDS,
} from "../../constants";
import { navigation } from "../../fixtures";
import { useLogEvent } from "../../contexts/Analytics";
import { OnSiteMessages, NotificationCenter } from "../OnSiteMessages";
import OnMasqueradingMessage from "../OnMasqueradingMessage";
import Link from "../Link";
import RedirectToLoginButton from "../RedirectToLoginButton";
import RedirectToRegistrationButton from "../RedirectToRegistrationButton";

function NavigationMenuLink({ disabled, className, children, ...props }) {
  return (
    <Link
      onPress={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
      legacyBehavior
      passHref
      {...props}
    >
      <NavigationMenu.Link
        className={twMerge(className, disabled && "cursor-not-allowed")}
      >
        {children}
      </NavigationMenu.Link>
    </Link>
  );
}

export default function AppBar() {
  const router = useRouter();
  const [activeMenuItem, setActiveMenuItem] = React.useState(undefined);
  const [navigationMenuListRef, setNavigationMenuListRef] =
    React.useState(null);
  const [activeTriggerOffset, setActiveTriggerOffset] = React.useState();
  const [activeCompactMenuItem, setActiveCompactMenuItem] =
    React.useState(undefined);
  const viewChampionCollectionRef = React.useRef();
  const { user = {}, logout, isLoading: isUpdatingUser } = useUser();
  const { profile } = useProfile();
  const hasCurriculumAccess = useHasCurriculumAccess();
  const curriculum = useCurriculum();
  const [navbarRef, navbarBounds] = useMeasure();
  const { logEvent: logLinkClickedEvent } = useLogEvent({
    event: "Link Clicked",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const champ = profile?.currentClassroomChamp;
  const compactMenuKey = "compactMenu";

  const { logEvent: logLogoutEvent } = useLogEvent({
    event: "Logout",
    properties: {
      sourceElement: SECTIONS_TYPES.NAV,
      sourceName: SECTIONS.LOGOUT_BUTTON,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const onTriggerNodeUpdate = (trigger, activeTrigger) => {
    if (trigger && navigationMenuListRef && activeMenuItem === activeTrigger) {
      const navigationMenuListWidth = navigationMenuListRef.offsetWidth;
      const navigationMenuListCenter = navigationMenuListWidth / 2;

      const triggerOffsetRight =
        navigationMenuListWidth -
        trigger.offsetLeft -
        trigger.offsetWidth +
        trigger.offsetWidth / 2;

      setActiveTriggerOffset(
        Math.round(navigationMenuListCenter - triggerOffsetRight),
      );
    } else if (activeMenuItem === "") {
      setActiveTriggerOffset(null);
    }
    return trigger;
  };

  return (
    <div className="bg-black gn-nav z-50 sticky top-0">
      <OnMasqueradingMessage />

      <OnSiteMessages anchorBounds={navbarBounds} />

      <div
        className="flex items-center justify-between h-navbar container"
        ref={navbarRef}
        id={ELEMENT_IDS.NAVBAR}
      >
        {/* Mobile Nav Start */}
        <NavigationMenu.Root
          value={activeCompactMenuItem}
          onValueChange={(menuItem) => setActiveCompactMenuItem(menuItem)}
          className="block lg:hidden"
        >
          <NavigationMenu.List className="flex items-center list-none">
            <NavigationMenu.Item value={compactMenuKey}>
              <NavigationMenu.Trigger className="mr-sm p-xs text-white hover:text-mint group">
                <div className="group-data-[state=closed]:hidden">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="block h-7 w-7" aria-hidden="true" />
                </div>

                <div className="group-data-[state=open]:hidden">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="block h-7 w-7 " aria-hidden="true" />
                </div>
              </NavigationMenu.Trigger>

              <NavigationMenu.Content
                forceMount
                className={twMerge(
                  "px-lg pt-md pb-lg overflow-y-auto bg-gray-800 hidden",
                  activeCompactMenuItem === compactMenuKey && "block",
                )}
                style={{
                  maxHeight: `calc(100vh - ${navbarBounds.bottom}px)`,
                }}
              >
                <ul className="space-y-md list-none">
                  <li>
                    {hasCurriculumAccess ? (
                      <NavigationMenuLink
                        href={`/${ROUTE_PREFIX.CURRICULUM}/${curriculum.id}`}
                        events={[
                          {
                            event: "Link Clicked",
                            properties: {
                              title: "SuperNoodle",
                            },
                          },
                        ]}
                        className="text-transparent whitespace-nowrap bg-clip-text bg-gradient-to-r from-yellow to-pink gn-text-lg font-bold"
                      >
                        SuperNoodle
                      </NavigationMenuLink>
                    ) : (
                      <AgeGatedPressable
                        onPress={() => {
                          logLinkClickedEvent({
                            title: "SuperNoodle",
                          });

                          return router.push(URLS.SUPERNOODLE_URL);
                        }}
                        className="text-transparent whitespace-nowrap bg-clip-text bg-gradient-to-r from-yellow to-pink gn-text-lg font-bold"
                      >
                        SuperNoodle
                      </AgeGatedPressable>
                    )}
                  </li>

                  {navigation.compactMenu.map((item) => {
                    const isDisabled =
                      user.features.hideSearchPage &&
                      item.slug === ROUTE_PATHS.SEARCH;
                    return (
                      <li
                        key={item.slug}
                        className={twMerge(
                          "flex items-center gn-text-lg font-bold text-white",
                          isDisabled && "opacity-30",
                        )}
                      >
                        <NavigationMenuLink
                          disabled={isDisabled}
                          href={item.slug}
                          events={[
                            {
                              event: "Link Clicked",
                              properties: {
                                title: item.name,
                              },
                            },
                          ]}
                        >
                          {item.name}
                        </NavigationMenuLink>
                      </li>
                    );
                  })}
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenuLink
                href={ROUTE_PREFIX.HOME}
                events={[
                  {
                    event: "Link Clicked",
                    properties: {
                      title: "GoNoodle Logo",
                    },
                  },
                ]}
              >
                <NextImage
                  src={`${URLS.GN_ASSETS_BASE_URL}/web_public/GoNoodleLogo_Icon_Reverse.svg`}
                  width={51}
                  height={38}
                  className="transform transition hover:scale-110"
                  alt="GoNoodle logo - Home"
                  priority={true}
                />
              </NavigationMenuLink>
            </NavigationMenu.Item>
          </NavigationMenu.List>

          {/* Translate y is used to account for padding around navigation menu trigger so that viewport moves under navbar */}
          <NavigationMenu.Viewport className="absolute w-screen min-h-screen left-0 translate-y-3 bg-gray-800" />
        </NavigationMenu.Root>
        {/* Mobile Nav End */}

        {/* Desktop Nav Start */}

        <Link
          href={ROUTE_PREFIX.HOME}
          className="hidden lg:block shrink-0 focus:outline-mint"
          events={[
            {
              event: "Link Clicked",
              properties: {
                title: "GoNoodle Logo",
              },
            },
          ]}
        >
          <NextImage
            width={150}
            height={26}
            className="transform transition hover:scale-110 "
            src={`${URLS.GN_ASSETS_BASE_URL}/web_public/GoNoodleLogo_Registered_Reverse.svg`}
            priority={true}
            alt="GoNoodle logo - Home"
          />
        </Link>

        <NavigationMenu.Root
          value={activeMenuItem}
          onValueChange={(menuItem) => setActiveMenuItem(menuItem)}
          className="hidden lg:block relative mx-auto"
        >
          <NavigationMenu.List
            ref={setNavigationMenuListRef}
            className="flex items-center list-none"
          >
            {navigation.fullMenu.map((menuItem) => {
              const camelizedTitle = camelize(menuItem.title);
              const hasSubMenus = menuItem.sections.length > 1;

              if (hasSubMenus) {
                return (
                  <NavigationMenu.Item
                    value={menuItem.title}
                    key={menuItem.title}
                  >
                    <NavigationMenu.Trigger
                      id={camelizedTitle}
                      aria-controls={camelizedTitle}
                      ref={(node) => onTriggerNodeUpdate(node, menuItem.title)}
                      className="text-white rounded px-sm py-xs gn-text-sm font-bold mr-0.5 transition-colors focus:outline-none focus:ring-2 hover:ring-2 ring-mint"
                    >
                      {menuItem.title}
                    </NavigationMenu.Trigger>

                    <NavigationMenu.Content
                      forceMount
                      asChild
                      className={twMerge(
                        "w-max hidden",
                        activeMenuItem === menuItem.title && "block",
                      )}
                    >
                      <ul
                        id={`${camelizedTitle}NavigationContent`}
                        aria-labelledby={camelizedTitle}
                        className="grid grid-cols-3 grid-flow-row-dense p-md bg-white shadow-lg rounded-t list-none"
                      >
                        {menuItem.sections.map((section, index) => (
                          <React.Fragment key={section.slug}>
                            <li
                              style={{
                                // Using style instead of className to avoid issue with dynamic grid-column-start/end.
                                gridColumnStart: index + 1,
                                gridColumnEnd: index + 2,
                              }}
                            >
                              <h3 className="gn-text-sm font-bold uppercase whitespace-nowrap">
                                {section.heading}
                              </h3>
                            </li>

                            {section.tags.map((tag) => (
                              <li
                                key={tag.slug}
                                className="mt-sm"
                                style={{
                                  // Using style instead of className to avoid issue with dynamic grid-column-start/end.
                                  gridColumnStart: index + 1,
                                  gridColumnEnd: index + 2,
                                }}
                              >
                                <NavigationMenuLink
                                  href={tag.slug}
                                  className="flex w-fit gn-text-sm hover:text-pink py-xs whitespace-nowrap focus:outline-none focus:ring-2 ring-mint"
                                  events={[
                                    {
                                      event: "Link Clicked",
                                      properties: {
                                        title: tag.name,
                                      },
                                    },
                                  ]}
                                >
                                  {tag.name}
                                </NavigationMenuLink>
                              </li>
                            ))}

                            <li
                              className="mt-sm"
                              style={{
                                // Using style instead of className to avoid issue with dynamic grid-column-start/end.
                                gridColumnStart: index + 1,
                                gridColumnEnd: index + 2,
                              }}
                            >
                              <NavigationMenuLink
                                href={section.slug}
                                className="block w-fit gn-text-sm font-bold py-xs hover:text-purple focus:outline-none focus:ring-2 ring-mint"
                                events={[
                                  {
                                    event: "Link Clicked",
                                    properties: {
                                      title: "Show All",
                                    },
                                  },
                                ]}
                                aria-label={`Show all ${section.heading}`}
                              >
                                Show All
                              </NavigationMenuLink>
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>
                );
              }

              return (
                <NavigationMenu.Item key={menuItem.title}>
                  <NavigationMenuLink
                    href={menuItem.sections[0].slug}
                    className="text-white rounded px-sm py-xs gn-text-sm font-bold mr-0.5 transition-colors focus:outline-none focus:ring-2 hover:ring-2 ring-mint"
                    events={[
                      {
                        event: "Link Clicked",
                        properties: {
                          title: `${menuItem.title} - Show All`,
                        },
                      },
                    ]}
                  >
                    {menuItem.title}
                  </NavigationMenuLink>
                </NavigationMenu.Item>
              );
            })}

            <NavigationMenu.Item>
              {hasCurriculumAccess ? (
                <NavigationMenuLink
                  href={`/${ROUTE_PREFIX.CURRICULUM}/${curriculum.id}`}
                  className="gn-text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow to-pink gn-text-sm rounded px-sm py-xs transition-colors focus:outline-none focus:ring-2 hover:ring-2 ring-mint"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title: "SuperNoodle",
                      },
                    },
                  ]}
                >
                  SuperNoodle
                </NavigationMenuLink>
              ) : (
                <AgeGatedPressable
                  onPress={() => {
                    logLinkClickedEvent({
                      title: "SuperNoodle",
                    });

                    return router.push(URLS.SUPERNOODLE_URL);
                  }}
                  className="gn-text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow to-pink gn-text-sm rounded px-sm py-xs transition-colors focus:outline-none focus:ring-2 hover:ring-2 ring-mint"
                >
                  SuperNoodle
                </AgeGatedPressable>
              )}
            </NavigationMenu.Item>
          </NavigationMenu.List>

          <div className="absolute flex justify-center left-0 top-full mt-2 w-full">
            <NavigationMenu.Viewport
              forceMount
              className="w-[var(--radix-navigation-menu-viewport-width)] transition-all duration-700 "
              style={{
                // Avoid transitioning from initial position when first opening
                display: !activeTriggerOffset ? "none" : undefined,
                transform: `translateX(${activeTriggerOffset}px)`,
              }}
            />
          </div>
        </NavigationMenu.Root>
        {/* Desktop Nav End */}

        <div className="flex items-center justify-center">
          {user.isLoggedIn && (
            <>
              <Link
                href={`/${ROUTE_PREFIX.FAVORITES}`}
                className="mr-4 flex-shrink-0"
              >
                <span className="text-white hover:text-mint">
                  <span className="sr-only">Favorites</span>
                  <HeartIcon className="h-6 w-6" aria-hidden="true" />
                </span>
              </Link>

              {user.features.notificationCenter && <NotificationCenter />}
            </>
          )}

          {!user.isLoggedIn ? (
            <>
              <RedirectToRegistrationButton
                className="mx-xs font-regular lg:whitespace-nowrap"
                referrer={{
                  sourceElement: SECTIONS_TYPES.NAV,
                  sourceName: SECTIONS.CREATE_ACCOUNT_BUTTON,
                }}
              />

              <RedirectToLoginButton
                className="mx-xs font-regular lg:whitespace-nowrap"
                referrer={{
                  sourceElement: SECTIONS_TYPES.NAV,
                  sourceName: SECTIONS.LOGIN_BUTTON,
                }}
              />
            </>
          ) : (
            <Popover.Root>
              <Popover.Trigger className="group focus:ring rounded-full focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900 focus:outline-none">
                <div className="flex items-center lg:hidden">
                  <div className="group-data-[state=closed]:hidden h-9 w-9 text-white hover:text-mint flex items-center justify-center">
                    <span className="sr-only">Close profile menu</span>
                    <XIcon
                      className="block h-7 w-7 text-center"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="group-data-[state=open]:hidden w-9 h-9 rounded-full relative overflow-hidden bg-purple">
                    {champ && (
                      <Image
                        className="absolute inset-0 w-full h-full object-cover"
                        sources={{
                          "regular@1x": champ.images.headshot["1x"],
                          "regular@2x": champ.images.headshot["2x"],
                          "regular@3x": champ.images.headshot["3x"],
                        }}
                        alt=""
                      />
                    )}
                  </div>

                  <ChevronDownIcon
                    className="transition-transform duration-300 ease-in-out group-data-[state=open]:rotate-180 text-white ml-[6px] h-4 w-4"
                    aria-hidden="true"
                  />
                </div>

                <div className="hidden lg:flex items-center space-x-[6px]">
                  <div className="w-[35px] h-[35px] rounded-full relative overflow-hidden bg-purple">
                    {champ && (
                      <Image
                        className="absolute inset-0 w-full h-full object-cover"
                        sources={{
                          "regular@1x": champ.images.headshot["1x"],
                          "regular@2x": champ.images.headshot["2x"],
                          "regular@3x": champ.images.headshot["3x"],
                        }}
                        alt=""
                      />
                    )}
                  </div>

                  <ChevronDownIcon
                    className="transition-transform duration-300 ease-in-out group-data-[state=open]:rotate-180 text-white h-4 w-4"
                    aria-hidden="true"
                  />
                </div>
              </Popover.Trigger>

              <Popover.Panel
                sideOffset={18} // This offset is calculated by (h-navbar/2) - trigger height (h-9)
                className="w-screen lg:w-fit lg:rounded-lg overflow-y-auto lg:min-w-[300px] z-[50]"
                style={{
                  maxHeight: `calc(100vh - ${navbarBounds.bottom}px)`,
                }}
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                  viewChampionCollectionRef.current.focus();
                }}
              >
                <div className="flex flex-col divide-y-2 divide-gray-300 bg-white">
                  <div className="flex flex-col items-center p-5">
                    {champ && (
                      <Image
                        className="w-52 h-52 object-contain mb-4"
                        sources={{
                          "regular@1x": champ.images.full["1x"],
                          "regular@2x": champ.images.full["2x"],
                          "regular@3x": champ.images.full["3x"],
                        }}
                        alt={champ.name}
                      />
                    )}

                    <h2 className="font-bold text-lg text-center leading-7 max-w-60 text-ellipsis overflow-hidden">
                      {profile?.name}
                    </h2>

                    <p className="text-center">
                      {profile?.classroomType === PROFILE_TYPES.CLASSROOM
                        ? `${profile?.size} ${
                            profile?.size > 1 ? "students" : "student"
                          } ${
                            profile?.school.id !== null
                              ? `at ${profile?.school.name}`
                              : ""
                          }`
                        : profile?.grade.name}
                    </p>

                    <Link
                      href={`/${ROUTE_PREFIX.TROPHIES}`}
                      ref={viewChampionCollectionRef}
                      className="font-semibold text-sm rounded p-xs focus:ring focus:ring-purple focus:outline-none text-purple "
                    >
                      View Badge and Champ Collection
                    </Link>
                  </div>

                  <div className="flex flex-col space-y-1 p-3">
                    <Link
                      href={`/${ROUTE_PREFIX.PROFILES}`}
                      autoFocus={true}
                      className="flex gap-3 items-center font-semibold text-sm hover:bg-gray-100 rounded p-sm focus:ring focus:ring-purple focus:outline-none"
                    >
                      <SwitchHorizontalIcon
                        className="text-purple w-4 h-4"
                        aria-hidden="true"
                      />
                      Manage Profiles
                    </Link>

                    <AgeGatedPressable
                      onPress={() =>
                        router.push(`/${ROUTE_PREFIX.ACCOUNT_SETTINGS}`)
                      }
                      className="flex gap-3 items-center font-semibold text-sm w-full text-left hover:bg-gray-100 rounded p-sm focus:ring focus:ring-purple focus:outline-none"
                    >
                      <UserIcon
                        className="text-purple w-4 h-4"
                        aria-hidden="true"
                      />
                      Account Settings
                    </AgeGatedPressable>

                    <Link
                      href="https://www.amazon.com/stores/page/BBE94FF8-64FA-40E2-87C7-BFAFD07D26D3"
                      className="flex gap-3 items-center font-semibold text-sm w-full text-left hover:bg-gray-100 rounded p-sm focus:ring focus:ring-purple focus:outline-none"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.99993 7.95L3.99993 8.5C3.7666 8.63333 3.5166 8.66667 3.24993 8.6C2.98327 8.53333 2.78327 8.38333 2.64994 8.15L0.649935 4.65C0.516602 4.41667 0.483268 4.16667 0.549935 3.9C0.616602 3.63333 0.766602 3.43333 0.999935 3.3L6.74993 0H8.49994C8.64994 0 8.77077 0.0458333 8.86244 0.1375C8.9541 0.229167 8.99994 0.35 8.99994 0.5V1C8.99994 1.55 9.19577 2.02083 9.58744 2.4125C9.9791 2.80417 10.4499 3 10.9999 3C11.5499 3 12.0208 2.80417 12.4124 2.4125C12.8041 2.02083 12.9999 1.55 12.9999 1V0.5C12.9999 0.35 13.0458 0.229167 13.1374 0.1375C13.2291 0.0458333 13.3499 0 13.4999 0H15.2499L20.9999 3.3C21.2333 3.43333 21.3833 3.63333 21.4499 3.9C21.5166 4.16667 21.4833 4.41667 21.3499 4.65L19.3499 8.15C19.2166 8.38333 19.0208 8.52917 18.7624 8.5875C18.5041 8.64583 18.2499 8.60833 17.9999 8.475L16.9999 7.975V17C16.9999 17.2833 16.9041 17.5208 16.7124 17.7125C16.5208 17.9042 16.2833 18 15.9999 18H5.99993C5.7166 18 5.4791 17.9042 5.28744 17.7125C5.09577 17.5208 4.99993 17.2833 4.99993 17V7.95ZM6.99994 4.6V16H14.9999V4.6L18.0999 6.3L19.1499 4.55L14.8499 2.05C14.5999 2.9 14.1291 3.60417 13.4374 4.1625C12.7458 4.72083 11.9333 5 10.9999 5C10.0666 5 9.2541 4.72083 8.56244 4.1625C7.87077 3.60417 7.39994 2.9 7.14994 2.05L2.84994 4.55L3.89993 6.3L6.99994 4.6Z"
                          fill="#7328ff"
                        />
                      </svg>
                      Merchandise
                    </Link>

                    <AgeGatedPressable
                      onPress={() => router.push(URLS.SUPPORT_URL)}
                      className="flex gap-3 items-center font-semibold text-sm w-full text-left hover:bg-gray-100 rounded p-sm focus:ring focus:ring-purple focus:outline-none"
                    >
                      <QuestionMarkCircleIcon
                        className="text-purple w-4 h-4"
                        aria-hidden="true"
                      />
                      Help Center for GrownUps
                    </AgeGatedPressable>

                    <Pressable
                      className={twMerge(
                        "flex gap-3 items-center font-semibold text-sm w-full text-left hover:bg-gray-100 rounded p-sm focus:ring focus:ring-purple focus:outline-none",
                        isUpdatingUser && "opacity-50",
                      )}
                      disabled={isUpdatingUser}
                      onPress={() => {
                        logLogoutEvent();
                        logout();
                      }}
                    >
                      <LogoutIcon
                        className="text-purple w-4 h-4"
                        aria-hidden="true"
                      />
                      Log Out
                    </Pressable>
                  </div>
                </div>
              </Popover.Panel>
            </Popover.Root>
          )}

          <div className="py-6 border-l rounded-full border-gray-600 ml-4" />

          {user.features.hideSearchPage ? (
            <Tooltip.Root delay={0}>
              <Pressable className="ml-3 md:ml-4 flex flex-row justify-center space-x-3 flex-shrink-0 cursor-not-allowed text-gray-500">
                <SearchIcon className="h-6 w-6" aria-hidden="true" />
                <span className="hidden md:block lg:hidden xl:block text-md font-bold">
                  Search
                </span>
                <Tooltip.Content
                  placement="bottom"
                  className="absolute top-0 mt-8 mr-4"
                >
                  <div className="bg-white p-4 rounded-lg shadow-lg">
                    <p className="text-sm text-gray-500">
                      search is not available at the moment
                    </p>
                  </div>
                </Tooltip.Content>
              </Pressable>
            </Tooltip.Root>
          ) : (
            <Link
              href={`/${ROUTE_PREFIX.SEARCH}`}
              className="ml-3 md:ml-4 flex flex-row justify-center space-x-3 flex-shrink-0 text-white hover:text-mint focus:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Search",
                  },
                },
              ]}
            >
              <SearchIcon className="h-6 w-6" aria-hidden="true" />
              <span className="hidden md:block lg:hidden xl:block text-md font-bold">
                Search
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
